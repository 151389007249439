import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/Layout'
import MetaData from '../components/seo/MetaData'
import Container from '../components/layout/Container'
import { Data } from '../models/StoryblokModel'
import WideContainer from '../components/layout/WideContainer'
import RecipeCard from '../components/cards/RecipeCard'
import { RecipeModel } from '../models/RecipeModel'
import Routes from '../constants/Routes';

interface Props {    
    data : Data,
    pageContext: PageContext,
    location:any
}

interface PageContext{
    tag:string,
    tagRegex:string
}

const TagTemplate : React.FunctionComponent<Props> = ({ data, pageContext }) =>{
    const content = data.allStoryblokEntry.edges.map( edge => JSON.parse(edge.node.content));
    console.log(content)

    return(
    <Layout>
      <MetaData 
        title={`Opskrifter: ${pageContext.tag}`}
        description={`Se alle vegetar opskrifter og retter, som er tagget med: ${pageContext.tag}. Du får gode billeder og simple instruktioner, så du selv kan lave dem. Klik her.`}
      />
      <div className="bg-white">
        <Container className="pt-4 text-center">
            <h1>Opskrifter: {pageContext.tag}</h1>
            <hr className="w-48 text-center mx-auto mb-2 border-2" />
        </Container>
        <WideContainer>
          <div className="grid grid-cols-1 md:grid-cols-3 mt-8">
              {content.map((item: RecipeModel, index: number) =>
                  <div key={index}>
                      <RecipeCard 
                          title={item.headline}
                          image={item.image.filename}
                          imageAlt={item.image.name || item.image.filename}
                          link={`/${Routes.RECIPEPREFIX}/${data.allStoryblokEntry.edges[index].node.slug}/`}
                          authorName={item.author.content.author_name}
                          authorImage={item.author.content.author_image.filename}
                          authorInstagramLink={item.author.content.author_instagram_url}
                          author_slug={item.author.slug}
                      />
                  </div>
              )}
          </div>
        </WideContainer>
      </div>
    </Layout>
    )
}

export const query = graphql`
query QueryRecipeWithTag($tagRegex: String){
  allStoryblokEntry (filter: {field_component: {eq: "Post"}, fields: {tags: {elemMatch: {tag: {regex: $tagRegex}}}}}){
            edges {
              node {
                id
                name
                created_at
                uuid
                slug
                full_slug
                content
              }
            }
          }
}`

export default TagTemplate